<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    :retain-focus="false"
    :value="dialog"
    :max-width="1200"
    @input="closeModal(false)"
  >
    <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            id="btn-close"
            name="btn-close"
            @click="closeModal(false)"
          >
            <v-icon>fa-solid fa-xmark</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="b-dialog--content">
          <v-row dense>
            <v-col cols="12" xl="7" lg="7" md="7" sm="6">
              <v-text-field
                id="name"
                name="name"
                label="Nome"
                dense
                outlined
                required
                :rules="[required]"
                v-model="model.name"
              />
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="6">
              <v-text-field
                id="document"
                name="document"
                label="CPF"
                dense
                outlined
                required
                :rules="[required, cpf]"
                v-mask="'###.###.###-##'"
                v-model="model.document"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <v-text-field
                id="cid"
                name="cid"
                label="CID"
                maxlength="3"
                dense
                outlined
                required
                :rules="[required]"
                v-model="model.cid"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseDatePicker
                id="birthDate"
                name="birthDate"
                label="Data de nascimento"
                required
                :rules="[required]"
                v-model="model.birthDate"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseDatePicker
                id="startLeaveDate"
                name="startLeaveDate"
                label="Início afastamento"
                required
                :rules="[required]"
                v-model="model.startLeaveDate"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <BaseDatePicker
                id="endLeaveDate"
                name="endLeaveDate"
                label="Fim afastamento"
                required
                :rules="[required]"
                v-model="model.endLeaveDate"
              />
            </v-col>
            <v-col cols="12" xl="12" lg="12" md="12" sm="6">
              <v-textarea
                id="reasonLeave"
                name="reasonLeave"
                label="Motivo do afastamento"
                outlined
                required
                :rules="[required]"
                v-model="model.reasonLeave"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions
          class="b-dialog--actions"
          v-permission="{
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }"
        >
          <BaseButton
            id="btn-save"
            name="btn-save"
            type="submit"
            color="primary"
            title="Salvar"
            :disabled="!valid"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { unmask } from '@/helpers/utils';
import { showMessage } from '@/helpers/messages';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    edit: {
      type: Boolean,
      required: true,
      default: false
    },
    selectItem: {
      type: Object,
      required: true
    },
    customerId: {
      type: String
    }
  },

  data: () => ({
    valid: true,
    model: {
      id: null,
      name: null,
      document: null,
      birthDate: null,
      startLeaveDate: null,
      endLeaveDate: null,
      cid: null,
      reasonLeave: null
    }
  }),

  created() {
    if (this.edit) {
      this.model = Object.assign({}, this.selectItem);
    }
  },

  methods: {
    closeModal(redirect) {
      this.$emit('closeModal', redirect);
    },

    save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      const model = Object.assign({}, this.model);
      model.document = unmask(model.document);

      const params = {
        contractId: this.$route.params.id,
        customerId: this.customerId,
        ...model
      };

      if (this.edit) {
        this.update(params);
      } else {
        this.create(params);
      }
    },

    async create(params) {
      try {
        const contractService = new ContractService();
        const { status } = await contractService.createLeaveBeneficiaries(
          params
        );

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async update(params) {
      try {
        const contractService = new ContractService();
        const { status } = await contractService.updateLeaveBeneficiaries(
          params
        );

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.closeModal(true);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
